import { useEffect, useState, forwardRef, useImperativeHandle } from 'react';
import { useForm, useFieldArray, useWatch } from 'react-hook-form';
import { ToggleSwitchInput } from '../FormComponents/ToggleSwitchInput/ToggleSwitchInput';
import { DynamicTypeInput } from 'components/Form/DynamicTypeInput/DynamicTypeInput';
import styles from './ChecksSelector.module.scss';
import { ReferenceSelectorDetails } from './components/ReferenceSelectorDetails/ReferenceSelectorDetails';
import { CandidateQuestionnaireSelectorDetails } from './components/CandidateQuestionnaireSelectorDetails/CandidateQuestionnaireSelectorDetails';
import { CustomReferenceCheckDetails } from './components/CustomReferenceCheckDetails/CustomReferenceCheckDetails';
import { DocumentSigningSelectorDetails } from './components/DocumentSigningSelectorDetails/DocumentSigningSelectorDetails';
import { Select, InputGroup } from 'components/FormComponents';
import cn from 'classnames';

export const SelectorTypes = {
  REFERENCE: "reference",
  POLICE_VETTING: 'police_vetting',
  BACKGROUND_CHECKS: 'background_checks',
  QUESTIONNAIRE: 'candidate_questionnaire',
  CUSTOM_REFERENCE: 'custom_reference'
}

export const ChecksSelector = forwardRef(({ 
  companyChecks = [],
  form,
  forms,
  targetForm,
  documents,
  bundles,
  type,
  bulk,
  defaultValues,
  className,
  allowUploads       = false,
  setValidity        = () => {},
  onChange           = () => {},
  onSubmit           = () => {},
  getUpload          = () => {},
  signUpload         = () => {},
  createUpload       = () => {},
  deleteUpload       = () => {},
}, ref) => {

  const { register, watch, reset, control, setValue, formState: { errors }, handleSubmit } = useForm();
  const { fields: checks, append, remove } = useFieldArray({ control, name: 'checks' });

  const values = useWatch({ control });

  const [bundleId, setBundleId] = useState();

  const [enabledChecks, setEnabledChecks] = useState({});

  // Default values
  useEffect(() => {
    if(!defaultValues) return;
    reset({ checks: defaultValues });
  }, [defaultValues])

  // Set bundle values
  useEffect(() => {
    if(!bundleId) return;
    const bundle = bundles.find(bundle => bundle.id === bundleId)
    reset({ checks: bundle.checks_data });
  }, [bundleId])

  // Expose functions
  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit((data) => {
        delete data.checks[-1];
        onSubmit(data.checks);
      })()
    }
  }));

  // Clear Bundle
  const clearBundle = () => { 
    setBundleId();
    reset({ checks: [] });
  }

  // Set enabled checks
  useEffect(() => {
    let enabledChecks = {}
    checks.forEach((check, index) => { enabledChecks[check.type] = index });
    setEnabledChecks(enabledChecks)
  }, [checks])

  // Set validity
  useEffect(() => {
    setValidity(checks.length > 0)
  }, [checks?.length])

  // OnChange
  useEffect(() => {
    onChange(values);
  }, [values])

  const renderDetails = (companyCheck) => {
    if(!companyCheck.type) return null;

    switch(companyCheck.type) {
      case 'reference': 
        return (
          <ReferenceSelectorDetails
            key={companyCheck.type}
            index={enabledChecks.reference}
            append={append}
            remove={remove}
            register={register}
            watch={watch}
            credits={companyCheck?.credits}
            setValue={setValue}
            form={form}
            forms={forms}
            type={type}
            bulk={bulk}
            targetForm={targetForm}
            documents={documents}
            signUpload={signUpload}
            createUpload={createUpload}
            handleDeleteUpload={deleteUpload}
            allowUploads={allowUploads}
          />
        )
      case 'custom_reference': 
        return (
          <CustomReferenceCheckDetails
            key={companyCheck.type}
            index={enabledChecks.custom_reference}
            append={append}
            remove={remove}
            register={register}
            control={control}
            watch={watch}
            setValue={setValue}
            credits={companyCheck?.credits}
            bulk={bulk}
            documents={documents}
            forms={forms}
            signUpload={signUpload}
            createUpload={createUpload}
            handleDeleteUpload={deleteUpload}
            allowUploads={allowUploads}
          />
        )
      case 'candidate_questionnaire': 
        return (
          <CandidateQuestionnaireSelectorDetails
            key={companyCheck.type}
            index={enabledChecks.candidate_questionnaire}
            append={append}
            remove={remove}
            register={register}
            watch={watch}
            credits={companyCheck?.credits}
            candidateForms={forms.questionnaires} 
          />
        )
      case 'document_signing': 
        return (
          <DocumentSigningSelectorDetails
            key={companyCheck.type}
            index={enabledChecks.document_signing}
            documents={documents}
            watch={watch}
            register={register}
            append={append}
            remove={remove}
            setValue={setValue}
            control={control}
            getUpload={getUpload}
            signUpload={signUpload}
            createUpload={createUpload}
            deleteUpload={deleteUpload}
            credits={companyCheck?.credits}
          />
        )
      default:
        return (
          <SelectorDetails
            key={companyCheck.type}
            companyCheck={companyCheck}
            index={enabledChecks[companyCheck.type]}
            control={control}
            errors={errors}
            append={append}
            remove={remove}
            watch={watch}
            register={register}
          />
        )
    }
  }

  return (
    <form className={cn(styles.root, { [className]: className })}>
      {bundles?.length > 0 && 
        <InputGroup title='Bundle' className={cn(styles.bundle, 'u-margin-bottom')}>
          <Select inputProps={{ value: bundleId, onChange: (e) => setBundleId(e.target.value) }} value={bundleId} placeholder='Select Bundle...'>
            {bundles.map(bundle => (
              <Select.Item key={bundle.id} value={bundle.id}>{bundle.name}</Select.Item>
            ))}
          </Select>
          {bundleId &&
            <a className={styles.clear_bundle} onClick={clearBundle}>Clear Bundle</a>
          }
        </InputGroup>
      }
      {(!type || type === SelectorTypes.BACKGROUND_CHECKS) && 
        companyChecks.map(companyCheck => renderDetails(companyCheck))
      }
      {type === SelectorTypes.REFERENCE &&
        <ReferenceSelectorDetails
          index={enabledChecks.reference}
          append={append}
          remove={remove}
          register={register}
          watch={watch}
          credits={companyChecks.find(companyCheck => companyCheck.type === 'reference')?.credits}
          setValue={setValue}
          form={form}
          forms={forms}
          type={type}
          bulk={bulk}
          targetForm={targetForm}
          documents={documents}
          signUpload={signUpload}
          createUpload={createUpload}
          handleDeleteUpload={deleteUpload}
          allowUploads={allowUploads}
        />
      }
      {type === SelectorTypes.POLICE_VETTING &&
        <SelectorDetails
          companyCheck={companyChecks.find(companyCheck => companyCheck.type === 'police_vetting')}
          index={enabledChecks?.police_vetting}
          control={control}
          append={append}
          remove={remove}
          watch={watch}
          register={register}
        />
      }
      {type === SelectorTypes.QUESTIONNAIRE &&
        <CandidateQuestionnaireSelectorDetails
          index={enabledChecks.candidate_questionnaire}
          append={append}
          remove={remove}
          register={register}
          watch={watch}
          credits={companyChecks.find(companyCheck => companyCheck.type === 'candidate_questionnaire')?.credits}
          candidateForms={forms.questionnaires} 
        />
      }
      {type === SelectorTypes.CUSTOM_REFERENCE &&
        <CustomReferenceCheckDetails
          index={enabledChecks.custom_reference}
          append={append}
          remove={remove}
          control={control}
          register={register}
          watch={watch}
          setValue={setValue}
          documents={documents}
          credits={companyChecks.find(companyCheck => companyCheck.type === 'custom_reference')?.credits}
          forms={forms}
          signUpload={signUpload}
          createUpload={createUpload}
          handleDeleteUpload={deleteUpload}
          allowUploads={allowUploads}
        />
      }
    </form>
  )
})


const SelectorDetails = ({ 
  companyCheck, 
  index,
  errors,
  control,
  append   = () => {},
  remove   = () => {},
  watch    = () => {},
  register = () => {},
}) => {

  const checkType = companyCheck.check_type;

  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (enabled && isNaN(index)) {
      append({
        type: checkType.slug,
        details: {}
      })
    } else if (!enabled && index >= 0) {
      remove(index);
    }
  }, [enabled])

  useEffect(() => {
    if (index >= 0 && !enabled) setEnabled(true);
    else if (isNaN(index) && enabled) setEnabled(false);
  }, [index])

  const metConditions = (field) => {
    if(!field.conditions) return true;

    let conditionsMet = true;

    if(field.conditions) {
      field.conditions.forEach(({ code, op, value }) => {
        let conditionMet = false;
        let conditionValue = code.includes('configuration.') ? getConfigurationValue(code) : watch(`checks.${index}.details.${code}`);

        switch(op) {
          case 'is': 
            conditionMet = conditionValue == value;
            break;
          case 'is_not': 
            conditionMet = conditionValue != value;
            break;
          case 'not_in': 
            conditionMet = !value.includes(conditionValue);
            break;
          case 'in': 
            conditionMet = value.includes(conditionValue);
            break;
          case 'contains': 
            conditionMet = conditionValue ? conditionValue.includes(value) : false;
            break;
        }

        if(!conditionMet) conditionsMet=false;
      });
    }

    return conditionsMet;
  }

  const getFieldOptions = (field) => {
    if(!field.options) 
      return;

    if(Array.isArray(field.options))
      return field.options;

    const options = getConfigurationValue(field.options);
    return options.map(option => ({ name: option.value, value: option.value }));
  }

  const getConfigurationValue = (keysString) => {
    const companyCheckKeys = keysString.split('.');
    let value = companyCheck;
    companyCheckKeys.forEach(key => {
      value = value[key];
    });

    return value;
  }

  return (
    <ToggleSwitchInput
      text={checkType.title}
      logo={checkType.logo}
      subtext={(companyCheck.credits < 0 ? "Unlimited" : companyCheck.credits) + " remaining"}
      value={enabled}
      onChange={e => setEnabled(e.target.checked)}
    >
      {checkType.client_fields?.length > 0 && enabled && index >= 0 && 
        checkType.client_fields?.map(field => {
          if(!metConditions(field)) return;

          const fieldName = `checks.${index}.details.${field.code}`;
          const required = checkType.client_required_fields?.includes(field.code);
          const fieldTitle = required ? `${field.title} *` : field.title;
          const error = fieldName.split('.').reduce(function(prev, curr) {
              return prev ? prev[curr] : null
          }, errors)

          return (
            <DynamicTypeInput 
              key={field.code} 
              title={fieldTitle}
              fieldName={fieldName}
              fieldOptions={getFieldOptions(field)}
              type={field.type}
              required={required}
              control={control}
              error={error?.type}
              noErrorMessage={true}
              register={register}
              watch={watch}
            />
          )
        })
      } 
    </ToggleSwitchInput>
  )
}