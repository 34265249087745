import { useState } from 'react';
import { List } from 'components/List/List';
import { FormEdit } from 'components/Form/FormEdit/FormEdit';
import { AiReferenceModal } from '../../components/AiReferenceModal/AiReferenceModal';
import { FormTypes } from 'constants/forms';
import cn from 'classnames';
import { useFormsApiQuestionsMutation } from 'api/forms-api';

export const ReferenceForms = ({ 
  forms, 
  company,
  teams,
  onSave      = () => {}, 
  deleteForm  = () => {},
  setLoading  = () => {},
}) => {

  const [form, setForm] = useState();
  const [aiReferenceModal, setAiReferenceModal] = useState(false);

  const [getAiForm] = useFormsApiQuestionsMutation();

  const createNewForm = () => {
    setForm({ name: 'New Form', team_id: null, type: FormTypes.REFEREE, fields: [] })
  }

  const handleUpload = async(data) => {
    setLoading(true);
    const { data: response } = await getAiForm(data);

    let aiFields = [];
    let order = 0;

    response.result.forEach(field => {
      order++;
      aiFields.push({order: order, check_part: "CUSTOM", locked: false, text: field, type: "long_text"});
    }); 
    setForm({...form, fields: aiFields})
    setLoading(false);

    setAiReferenceModal(false);
  }

  const handleSave = async(data) => {
    const updatedForm = await onSave(data);
    if(updatedForm) setForm(updatedForm);
  }

  const createAiForm = () => {
    setAiReferenceModal(true);
  }

  const copyFromForm = (copyFrom) => {
    if(!copyFrom) return;

    let newForm = {...copyFrom}
    delete newForm.id;

    newForm.name += ' (Copy)';

    newForm.fields.map(field => {
      let newField = {...field} 
      delete newField.id;
      return newField;
    });

    setForm(newForm);
  }

  return (
    <div className={cn('u-flex-box', 'u-flex-align-start')}>
      <AiReferenceModal
        visible={aiReferenceModal}
        handleUpload={handleUpload}
        onClose={() => setAiReferenceModal(false)}
      />
      <List 
        title='Forms'
        subtitle='Create and configure forms used to complete a reference check.'
        className='u-margin-right--large u-flex-align-self-normal'
        onNew={createNewForm}
      >
        <List.Category title='Questionnaire Forms'>
          {forms.map(form => (
            <List.Item
              key={form.id}
              title={form.name}
              value={form}
              onClick={setForm}
            />
          ))}
        </List.Category>
      </List>
      {form && 
        <FormEdit 
          company={company}
          form={form}
          teams={teams}
          onCopy={copyFromForm}
          onSave={handleSave}
          onDelete={deleteForm}
          onClose={() => setForm(null)}
          onAi={createAiForm}
        />
      }
    </div>
  )
}