import React from 'react';
import { Button } from 'components/FormComponents';
import styles from './CustomButton.module.scss';
import cn from 'classnames';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';

export const CustomButton = ({ 
  completed,
  blockOnComplete,
  brand,
  width="100%",
  type="primary",
  icon,
  className,
  disabled,
  children,
  submit,
  small = false,
  onClick = () => {}
}) => {
 
  const buttonStyle = () => {
    const custom_style = brand && brand.brand_active && brand.color_active;
    
    let styles = {
      textTransform: 'none',
      fontSize: '15px',
      width: width,
      height: small ? '40px' : '55px'
    }

    if(type === 'transparent') { 
      styles.backgroundColor = "#FFF";
      styles.color = brand.color_button;
    } else if (type === 'outline') {
      styles.backgroundColor = "#FFF";
      styles.color = brand.color_button;
      styles.borderColor = brand.color_button;
    } else if (type === 'outline') {
      styles.backgroundColor = "#FFF";
      styles.color = brand.color_button;
      styles.borderColor = brand.color_button;
    } else if (type === 'secondary') {
      styles.backgroundColor = "#F9F9F9";
      styles.color = '#2D264D';
      styles.borderColor = brand.color_button;
    } else if (type === 'secondary') {
      styles.backgroundColor = "#F9F9F9";
      styles.color = '#2D264D';
      styles.borderColor = '#e5e5e5';
    } else if(custom_style) {
      styles.backgroundColor = brand.color_button;
      styles.color = brand.color_button_text;
    }  

    return styles;
  }

  return ( 
    <Button
      type={type}
      className={cn("u-margin-top", styles.root, { [className]: className, [styles.rootCompleted]: completed },)}
      style={buttonStyle()}
      disabled={disabled}
      onClick={() => { if(!blockOnComplete || !completed) onClick() }}
      icon={icon}
      submit={submit}
    >
      {completed && <FeatherIcon style={{ backgroundColor: brand.color_button }} icon='check' className={styles.completedIcon} />}
      {children}
    </Button>
  )
}