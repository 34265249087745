import { CollapsibleGroup, CollapsibleBlock } from 'components/CollapsibleBlock/CollapsibleBlock';
import { useForm, useWatch, useFieldArray } from 'react-hook-form';
import { useEffect, useState } from 'react';
import FeatherIcon from 'feather-icons-react';
import styles from './FormEdit.module.scss';
import { Tabs, Tab, Badge } from 'react-bootstrap';
import { IconButton } from 'components/IconButton/IconButton';
import { Button, EditableTitle, Select, InputGroup, Textarea } from 'components/FormComponents';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { FieldTypes, FieldTypesOptions } from 'constants/field-types-enum';
import { DefaultSettings } from './SettingsComponents/DefaultSettings/DefaultSettings';
import { MultipleChoiceSettings } from './SettingsComponents/MultipleChoiceSettings/MultipleChoiceSettings';
import { OpinionScaleSettings } from './SettingsComponents/OpinionScaleSettings/OpinionScaleSettings';
import { StarRatingSettings } from './SettingsComponents/StarRatingSettings/StarRatingSettings';
import { DateSettings } from './SettingsComponents/DateSettings/DateSettings';
import { FormPreviewModal } from './FormPreviewModal/FormPreviewModal';
import { LogicSettings } from './SettingsComponents/LogicSettings/LogicSettings';
import { operatorTypes } from 'constants/logic';
import { Permissions } from 'constants/permissions';
import { EditableSettings } from 'components/EditableSettings/EditableSettings';
import { useAccessControl } from 'hooks/access-control';
import cn from 'classnames';
import { FormTypes } from 'constants/forms';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'api/user-permissions-slice';

export const FormEdit = ({
  company,
  form,
  teams,
  blockBefore = 0,
  onCopy = () => { },
  onSave = () => { },
  onDelete = () => { },
  onClose = () => { },
  onAi
}) => {

  const { control, register, setValue, unregister, reset, watch, handleSubmit, formState: { errors } } = useForm({ defaultValues: form });
  const { fields, append, move, remove: removeField } = useFieldArray({ control, name: 'fields', keyName: 'key' });
  const { fields: logicJumps, append: appendLogic, remove: removeLogic } = useFieldArray({ control, name: 'logic_jumps', keyName: 'key' });

  const permissions = useSelector(selectPermissions);

  const hasAccess = useAccessControl(permissions);

  const nameValue = useWatch({ control, name: 'name' });
  const watchTeam = useWatch({ control, name: 'team_id' });
  const formValues = useWatch({ control });

  const [showPreview, setShowPreview] = useState(false);
  useEffect(() => reset(form), [form]);

  useEffect(() => {
    let updatedFields = [];
    let order = 0;

    fields.forEach(field => {
      order++;
      updatedFields.push({ ...field, order: order });
    });

    setValue('fields', updatedFields);
  }, [fields.length])

  const addField = () => {
    append({
      order: fields.length + 1,
      text: '',
      check_part: 'CUSTOM',
      type: FieldTypes.FreeText,
      required: true,
      options: { required: true, options: [{ value: '' }] }
    });
    window.scrollTo(0, document.body.scrollHeight);
  }

  const onDragEnd = ({ source, destination }) => {
    if (!source || !destination || destination.index < blockBefore) return;

    let first, last;

    if(source.index > destination.index) {
      first = destination.index;
      last = source.index;
    } else {
      first = source.index;
      last = destination.index;
    }

    move(source.index, destination.index);
    for(let i=first; i <= last; i++) setValue(`fields[${i}].order`, i+1);
  }

  const onError = (error) => {
    console.log("Validation Error");
    console.log(error);
  }

  const handleDelete = () => {
    onDelete(form);
    onClose();
  }

  return (
    <>
      <FormPreviewModal visible={showPreview} onClose={() => setShowPreview(false)} form={formValues} />
      <form onSubmit={handleSubmit(onSave, onError)} className={cn(styles.root, 'card', 'card-with-border', 'card--medium')}>
        <div>
          <div className='u-padding--large'>
            <div className={cn('u-flex-box', 'u-flex-justify-between', 'u-flex-align-center',)}>
              <div className='u-width-50'>
                {!form.team_id &&
                  <Badge className='u-margin-bottom--small'>Global</Badge>
                }
                <div className={cn('u-flex-box', 'u-flex-align-center')}>
                  <EditableTitle register={register} name='name' value={nameValue} />
                </div>
                <p className='t-small'>Edit your form below</p>
              </div>
              <div>
                {!!onAi && company?.ff_ai_forms &&
                  <IconButton
                    className='u-margin-right--small'
                    onClick={() => onAi(true)}
                    icon='robot'
                    prefix="fa"
                    tip='AI Reference Generator'
                  />
                }
                <IconButton icon='play' onClick={() => setShowPreview(true)} tip='Preview' className='u-margin-right--small' />
                {form.id && <IconButton icon='copy' onClick={() => onCopy(form)} tip='Make a copy' className='u-margin-right--small' />}
                {form.id && <IconButton icon='trash-2' onClick={handleDelete} tip='Delete form' />}
              </div>
            </div>
            <EditableSettings title='Settings' className='u-margin-top u-margin-bottom--0' startOpen={true}>
              <InputGroup title='Team'>
                <Select name='team_id' placeholder='Team' register={register} value={watchTeam} useDefault={!hasAccess(Permissions.TeamsRead)}>
                  {hasAccess(Permissions.TeamsRead) &&
                    <Select.Item value={null}>Global</Select.Item>
                  }
                  {teams.map(team => (
                    <Select.Item key={team.id} value={team.id}>{team.name}</Select.Item>
                  ))}
                </Select>
              </InputGroup>
              {form.type == FormTypes.CANDIDATE &&
                <InputGroup title='Description'>
                  <Textarea name='description' register={register} placeholder='Desciption...' inputProps={{ rows: 3 }} />
                </InputGroup>
              }
            </EditableSettings>
          </div>
          <Tabs defaultActiveKey="questions" className="tabs no-padding padding-small">
            <Tab eventKey="questions" title="Questions">
              <div className='background-secondary u-padding'>
                <CollapsibleGroup newStartOpen={true}>
                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId='fields'>
                      {({ innerRef, droppableProps, placeholder }) => (
                        <div className='u-width-100' {...droppableProps} ref={innerRef}>
                          {fields.map((field, index) => (
                            <FieldConfig
                              key={field.key}
                              index={index}
                              formField={field}
                              register={register}
                              watch={watch}
                              control={control}
                              errors={errors?.fields?.[index]}
                              remove={removeField}
                              unregister={unregister}
                            />
                          ))}
                          {placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                </CollapsibleGroup>
                <Button className='u-width-100 u-margin-top' type='secondary-reverse' onClick={addField}><FeatherIcon size={18} className='u-margin-right' icon='plus' />Add Field</Button>
              </div>
            </Tab>
            <Tab eventKey="logic" title="Logic">
              <div className='background-secondary u-padding'>
                <CollapsibleGroup newStartOpen={true}>
                  <div className='u-width-100'>
                    {logicJumps?.map((_, index) => (
                      <LogicConfig
                        key={index}
                        index={index}
                        blockBefore={blockBefore}
                        control={control}
                        register={register}
                        onDelete={removeLogic}
                      />
                    ))}
                  </div>
                </CollapsibleGroup>
                <Button className='u-width-100 u-margin-top' type='secondary-reverse' onClick={() => appendLogic({})}><FeatherIcon size={18} className='u-margin-right' icon='plus' />Add Logic Jump</Button>
              </div>
            </Tab>
          </Tabs>
          <div className={cn('card_footer', 'u-flex-box', 'u-flex-justify-between')}>
            <Button onClick={onClose} type='secondary'>Cancel</Button>
            <Button submit>Save</Button>
          </div>
        </div>
      </form>
    </>
  )
}

const FieldConfig = ({
  formField,
  index,
  register,
  control,
  watch,
  remove,
  unregister,
  errors,
}) => {

  const registerName = `fields.${index}`;

  const field = useWatch({ control, name: `fields.${index}` });

  const typeValue = useWatch({ control, name: `${registerName}.type` });

  // Remove options.options if not MultipleChoice
  useEffect(() => {
    if (typeValue === FieldTypes.MultipleChoice) return;
    unregister(`${registerName}.options.options`);
  }, [typeValue])

  const renderSettings = () => {
    if (field.locked) return;

    switch (field?.type) {
      case FieldTypes.MultipleChoice:
        return <MultipleChoiceSettings register={register} name={registerName} field={field} control={control} errors={errors} />
      case FieldTypes.OpinionScale:
        return <OpinionScaleSettings register={register} name={registerName} field={field} watch={watch} />
      case FieldTypes.StarRating:
        return <StarRatingSettings register={register} name={registerName} field={field} watch={watch} />
      case FieldTypes.Date:
        return <DateSettings register={register} name={registerName} field={field} />
      case FieldTypes.Statement:
        return null;
      default:
        return <DefaultSettings register={register} name={registerName} field={field} />
    }
  }

  return (
    <Draggable draggableId={formField.key} index={index} isDragDisabled={field.locked}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div {...draggableProps} {...dragHandleProps} ref={innerRef}>
          <CollapsibleBlock className='u-margin-bottom' keyCode={`${index}`}>
            <CollapsibleBlock.Header>
              <span className={styles.order}>{field.order}</span>
              <Select register={register} disabled={field.locked} name={`${registerName}.type`} className={styles.typeSelector} value={field.type}>
                {FieldTypesOptions.map(fieldType => (
                  <Select.Item
                    key={fieldType.key}
                    value={fieldType.key}
                    description={fieldType.subTitle}
                  >
                    {fieldType.title}
                  </Select.Item>
                ))}
              </Select>
              <Textarea
                register={register}
                name={`${registerName}.text`}
                validators={{ required: true }}
                error={errors?.text?.type}
                noErrorMessage
                placeholder='Enter question...'
                className={styles.textInput}
                dynamicHeight={true}
              />
              {field.locked ?
                <FeatherIcon icon='lock' size={18} className={styles.lockIcon} /> :
                <IconButton tip='Delete Field' className={styles.removeButton} onClick={() => remove(index)} icon='trash-2' />
              }
            </CollapsibleBlock.Header>
            <CollapsibleBlock.Content>
              {renderSettings()}
            </CollapsibleBlock.Content>
          </CollapsibleBlock>
        </div>
      )}
    </Draggable>
  )
}

const LogicConfig = ({
  control,
  blockBefore = 0,
  index,
  register,
  onDelete = () => { }
}) => {

  const watchFields = useWatch({ control, name: 'fields' });
  const logicField = useWatch({ control, name: `logic_jumps.${index}` });

  const operator = operatorTypes.find(operatorType => operatorType.value === logicField.operator);

  return (
    <CollapsibleBlock className={cn(styles.logicField, 'u-margin-bottom')} keyCode={`${index}`}>
      <CollapsibleBlock.Header>
        <span className={styles.logicFieldHeader}>If <b>({logicField.field})</b> is <b>{operator?.label}</b> <b>"{logicField.value}"</b>, then jump from <b>({logicField.from})</b> to <b>({logicField.to})</b></span>
        <IconButton className={styles.logicFieldRemoveButton} icon='trash-2' onClick={() => onDelete(index)} />
      </CollapsibleBlock.Header>
      <CollapsibleBlock.Content>
        <LogicSettings
          index={index}
          fields={watchFields}
          blockBefore={blockBefore}
          control={control}
          register={register}
        />
      </CollapsibleBlock.Content>
    </CollapsibleBlock>
  )
}