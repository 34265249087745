import { useState, useEffect } from 'react';
import styles from './FilePicker.module.scss';
import PropTypes from 'prop-types';
import FeatherIcon from 'feather-icons-react';
import { useDropzone } from 'react-dropzone';
import * as mime from 'react-native-mime-types';
import { uploadSigned } from 'helpers/aws-s3';
import { Loader } from 'components/Loader/Loader';
import cn from 'classnames';

export const FilePicker = ({
  value,
  modelParams = {},
  metadata = {},
  className,
  bucketFolder = 'answers',
  maxSize = 20971520,
  accept,
  useModel = false,
  onChange     = () => {},
  getUpload    = () => {},
  signUpload   = () => {},
  createUpload = () => {},
  deleteUpload = () => {},
}) => {

  const [fileName, setFileName] = useState();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if(!value) {
      setFileName(null);
      return;
    }

    if(useModel) {
      setFileName(value.file_name);
    } else {
      getUpload(value).then((upload) => setFileName(upload.file_name))
    }
  }, [value])

  /** 
   * --- On upload ---
   * 1) Get file meta
   * 2) Sign upload
   * 3) Upload to s3
   * 4) Create upload
   * 5) Set value
   */
   const onDrop = async(files) => {
    if(files.length < 1) return;

    setLoading(true);
    const file = files[0];
    if(!file) return;

    // Sign upload
    const params = {
      file_name: file.name,
      file_type: file.type,
      file_ext: mime.extension(file.type),
      bucket_folder: bucketFolder
    }

    const signedFile = await signUpload(params);

    // Upload to S3
    await uploadSigned(signedFile.url, file, file.type);

    // Save model 
    const uploadData = {
      ...modelParams,
      metadata,
      file_name: signedFile.file_name,
      file_type: signedFile.file_type,
      hash_key: signedFile.key
    }

    const upload = await createUpload(uploadData);

    setFileName(upload.file_name);
    onChange(useModel ? upload : upload.id);
    setLoading(false);
  }

  const onRemove = async() => {
    if(value) {
      setLoading(true);
      const id = useModel ? value.id : value;
      await deleteUpload(id);
      setLoading(false)
    }
    setFileName(null);
    onChange(null);
  }

  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, maxSize })

  return (
    <div className={cn(styles.root, { [className]: className })}>
      {loading && <Loader /> }
      
      {(fileName && !loading) && 
        <div className={styles.fileWrapper}>
          <FeatherIcon icon="file" size={40} />
          <p>{fileName}</p>
          <div className={styles.removeFileIconWrapper} onClick={onRemove}>
            <FeatherIcon className={styles.removeFileIcon} icon="trash-2" size={50} />
          </div>
        </div>
      }

      {(!fileName && !loading) && 
        <div {...getRootProps({ className: styles.dropzone })}>
          <input {...getInputProps()} />
          <FeatherIcon icon='upload' size={30} className={styles.icon} />
          <p className={styles.placeholder}>Drop your documents here, or <span className='u-link dark'>browse</span></p>
        </div>
      }
    </div>
  )
}

FilePicker.propTypes = {
  value: PropTypes.any,
  modelParams: PropTypes.object,
  metadata: PropTypes.object,
  className: PropTypes.string,
  bucketFolder: PropTypes.string,
  onChange: PropTypes.func,
  getUpload: PropTypes.func,
  signUpload: PropTypes.func,
  createUpload: PropTypes.func,
  deleteUpload: PropTypes.func,
  accept: PropTypes.object,
};