import { useEffect, useState } from 'react';
import { useLazyGetBrandQuery } from 'api/brands-api';
import { getTags } from 'api/tags-slice';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { 
  getCandidate,
  updateCandidate,
  deleteCandidate,
} from 'api/candidates-slice';
import { 
  useCreateCandidateCheckMutation,
  useCancelCandidateCheckMutation,
  useUpdateCandidateCheckStatusMutation,
  useCandidateCheckViewResultMutation,
  useLazyGetCandidateCheckResultQuery
} from 'api/candidate-checks-api'
import { 
  useGetCandidateChecksQuery,
  useGetCandidateRefereesQuery,
  useAddTagToCandidateMutation,
  useRemoveTagFromCandidateMutation,
  useCancelCandidateChecksMutation,
  useSendCandidateRequestEmailMutation,
  useAddCandidateHistoryMutation,
  useLazyGetCombinedPdfReportQuery,
  useLazyGetCandidateReportQuery
} from 'api/candidates-api';
import {
  useCreateRefereeMutation,
  useSendRefereeRequestEmailMutation,
  useSendReplacementRequestEmailMutation,
  useLazyGetPdfReportQuery,
  useUpdateRefereeMutation,
  useDeleteRefereeMutation
} from 'api/referees-api';
import { getForms } from 'api/forms-slice';
import { useGetUserTeamsQuery } from 'api/user-api';
import FeatherIcon from 'feather-icons-react';
import { messagePopUp } from 'api/app-slice';
import { MESSAGE_STATE_SUCCESS, MESSAGE_STATE_ERROR } from 'constants/message-app-state-contants';
import Moment from 'react-moment';
import { timeDifference } from 'helpers/date';
import { Questionnaire } from './tabs/Questionnaire/Questionnaire';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Tab, Badge } from 'react-bootstrap';
import { IconButton } from 'components/IconButton/IconButton';
import { IconStatusButton } from 'components/IconStatusButton/IconStatusButton';
import CopyEmail from 'components/CopyEmail/CopyEmail';
import AppPage from 'layouts/AppPage/AppPage';
import { errorRouter } from 'helpers/error-router';
import ReactGA from 'react-ga';
import styles from './Check.module.scss';
import { TagsMenu } from 'components/TagsMenu/TagsMenu';
import { AddCheckModal } from '../../components/AddCheckModal/AddCheckModal';
import Alert from 'components/Alert/Alert';
import { useDocumentTitle } from 'hooks/document-title';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Checks } from './tabs/Checks/Checks';
import { Candidate } from './tabs/Candidate/Candidate';
import { Referees } from './tabs/Referees/Referees';
import { History } from './tabs/History/History';
import { Reports } from './tabs/Reports/Reports';
import { Reference } from './tabs/Reference/Reference';
import { IntegrationsModal } from './components/IntegrationsModal/IntegrationsModal';
import cn from 'classnames';
import { Permissions } from 'constants/permissions';
import { useAccessControl } from 'hooks/access-control';
import { selectCandidates } from 'api/candidates-slice';
import { selectUser } from 'api/user-slice';
import { selectForms } from 'api/forms-slice';
import { selectCompany } from 'api/company-slice';
import { selectCompanyChecks } from 'api/company-checks-slice';
import { selectTags } from 'api/tags-slice';
import { useLocale } from 'hooks/locale';
import { selectPermissions } from 'api/user-permissions-slice';
import { getCustomFields, selectCustomFields } from 'api/candidate-custom-fields-slice';

const Check = () => {

  const { id, tab, target } = useParams();

  const user          = useSelector(selectUser);
  const company       = useSelector(selectCompany);
  const companyChecks = useSelector(selectCompanyChecks);
  const forms         = useSelector(selectForms);
  const candidates    = useSelector(selectCandidates);
  const tags          = useSelector(selectTags);
  const permissions   = useSelector(selectPermissions);
  const customFields  = useSelector(selectCustomFields);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasAccess = useAccessControl(permissions);

  const { data: checksData } = useGetCandidateChecksQuery(id)
  const { data: refereesData } = useGetCandidateRefereesQuery(id);
  const { dateSlashFormat } = useLocale(company?.locale);
  const { data: teams } = useGetUserTeamsQuery(user.id);

  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();
  const [getBrand] = useLazyGetBrandQuery()
  
  const [addTag] = useAddTagToCandidateMutation();
  const [removeTag] = useRemoveTagFromCandidateMutation();
  const [cancelCandidateChecks] = useCancelCandidateChecksMutation();
  const [sendCandidateRequestEmail] = useSendCandidateRequestEmailMutation();
  const [sendRefereeRequestEmail] = useSendRefereeRequestEmailMutation();
  const [sendReplacementRequestEmail] = useSendReplacementRequestEmailMutation();
  const [addCandidateHistory] = useAddCandidateHistoryMutation();
  const [updateReferee] = useUpdateRefereeMutation();
  const [createReferee] = useCreateRefereeMutation();
  const [createCandidateCheck] = useCreateCandidateCheckMutation();
  const [cancelCandidateCheck] = useCancelCandidateCheckMutation();
  const [candidateCheckViewResult] = useCandidateCheckViewResultMutation();
  const [updateCandidateCheckStatus] = useUpdateCandidateCheckStatusMutation();
  const [deleteReferee] = useDeleteRefereeMutation();

  const [getCombinedPdfReport] = useLazyGetCombinedPdfReportQuery();
  const [getPdfReport] = useLazyGetPdfReportQuery();
  const [getCandidateReport] = useLazyGetCandidateReportQuery();
  
  const onMessagePopUp = (text, state, hide=true) => dispatch(messagePopUp({ text, state, hide }));

  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState();
  const [checks, setChecks] = useState([]);
  const [navigation, setNavigation] = useState({ previous: null, next: null });
  const [showTagsMenu, setShowTagsMenu] = useState(false);
  const [showNewChecksModal, setShowNewChecksModal] = useState(false);
  const [showIntegrationsModal, setShowIntegrationsModal] = useState(false);

  const [referenceCheck, setReferenceCheck] = useState(null);
  const [customReferenceCheck, setCustomReferenceCheck] = useState(null);
  const [questionnaireCheck, setQuestionnaireCheck] = useState(null);
  const [referees, setReferees] = useState([]);
  const [refereesCompleted, setRefereesCompleted] = useState(0);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [emailLinkedOpened, setEmailLinkOpened] = useState(false);
  const [brand, setBrand] = useState();

  useDocumentTitle(candidate ? candidate.name : 'Loading...');
  
  useEffect(() => { ReactGA.pageview(window.location.pathname) }, []);

  useEffect(() => { if(checksData) setChecks(checksData)       }, [checksData])
  useEffect(() => { if(refereesData) setReferees(refereesData) }, [refereesData])

  // Load candidate
  useEffect(() => {
    try {
      setLoading(true);
      dispatch(getTags());
      dispatch(getCustomFields());
      dispatch(getCandidate(id)).then(
        ({ payload: candidate }) => {
          setCandidate(candidate);
          getBrand(candidate.brand_id).unwrap().then((brand) => {
            setBrand(brand);
            setLoading(false);
          })
        },
        (error) =>  navigate(errorRouter(error))
      );
    } catch(e) {} 
  }, [id]);

  // Set navigation
  useEffect(() => {
    if (candidates.length < 1) return;

    let ids = candidates.map((candidate) => candidate.id);
    let index = ids.findIndex((i) => i == id);
    if (index < 0) return;

    let previous = index === 0 ? null : ids[index - 1];
    let next = index === ids.length - 1 ? null : ids[index + 1];

    setNavigation({ previous, next });
  }, [id, candidates.length]);

  // Set referees completed
  useEffect(() => {
    setRefereesCompleted(
      referees.filter((referee) => referee.completed).length
    );
  }, [referees.length]);

  // Set special checks
  useEffect(() => {
    if (checks?.length < 1) return;

    setReferenceCheck(
      checks.find((check) => check.type === 'reference' && check.canceled == false)
    );
    setQuestionnaireCheck(
      checks.find((check) => check.type === 'candidate_questionnaire' && check.canceled == false)
    );
    setCustomReferenceCheck(
      checks.find((check) => check.type === 'custom_reference' && check.canceled == false)
    );
  }, [checks.length]);

  // TEMPLATE

  const handleAddTag = async (tagId) => {
    setLoading(true);
    setShowTagsMenu(false);
    try {
      const { data: tag } = await addTag({ id, tagId });
      setCandidate({...candidate, tags: [...candidate.tags, tag] });
    } catch (e) { }
    setLoading(false);
  };

  const hanleCandidateCancelation = async () => {
    setShowCancelModal((prevState) => !prevState);
    setLoading(true);
    const { data: result } = await cancelCandidateChecks(id);
    setCandidate(result);
    setLoading(false);
  };

  const handleArchiveToggle = async () => {
    setLoading(true);
    const { payload } = await dispatch(updateCandidate({ id, params: { archived: !candidate.archived } }));
    setCandidate(payload);
    setLoading(false);
  };

  const handleCheckDeletion = async () => {
    setLoading(true);
    await dispatch(deleteCandidate(id));
    setLoading(false);
    navigate('/dashboard');
  };

  const handleDownloadAll = async () => {
    setLoading(true);
    await getCandidateReport(candidate).unwrap();
    setLoading(false);
  }

  const handleTagDeletion = async (tagId) => {
    setLoading(true);
    await removeTag({ id, tagId });
    setCandidate({...candidate, tags: candidate.tags.filter(tag => tag.id !== tagId)});
    setLoading(false);
  };

  const hanldeSendCandidateRequestEmail = async () => {
    setLoading(true);
    await sendCandidateRequestEmail(id);
    onMessagePopUp("New candidate request Sent", MESSAGE_STATE_SUCCESS);
    setLoading(false);
  };

  const hanldeSendRefereeRequestEmail = async (refereeId) => {
    setLoading(true);
    const { data: result } = await sendRefereeRequestEmail(refereeId);
    if (result)
      onMessagePopUp("New referee request Sent", MESSAGE_STATE_SUCCESS);
    else 
      onMessagePopUp("Request not sent", MESSAGE_STATE_ERROR);
    setLoading(false);
  };

  const handleUpdateCandidate = async(params) => {
    setLoading(true);
    const { payload: candidate } = await dispatch(updateCandidate({ id, params }));
    setCandidate(candidate);
    setLoading(false);
  };

  const handleGetPdfReport = async(id, filename, combined=false) => {
    setLoading(true);
    if (combined) 
      await getCombinedPdfReport({ id, filename });
    else 
      await getPdfReport({ id, filename });
    setLoading(false);
  };

  const getCombinedReport = async(check) => {
    setLoading(true);
    await getCandidateCheckResult({ check, candidate });
    setLoading(false);
  }

  const handleNewChecks = async (checksData) => {
    setShowNewChecksModal(false);
    setLoading(true);
    const { data: candidateChecks } = await createCandidateCheck({ candidateId: candidate.id, data: checksData });
    setChecks(candidateChecks);
    setCandidate({ ...candidate, submitted_at: null, completed_at: null, status: "candidate" });
    setLoading(false);
  };

  const handleCancelCheck = async (candidateCheckId) => {
    setLoading(true);
    const { data: updatedCheck } = await cancelCandidateCheck(candidateCheckId);
    setChecks(checks.map(check => check.id === updatedCheck.id ? updatedCheck : check));
    setLoading(false);
  };

  const handleCandidateCheckViewResult = async (candidateCheckId) => {
    setLoading(true);
    const { data: base64String } = await candidateCheckViewResult({ id: candidateCheckId });
    const urlRegex = /https?:\/{2}(?:[\/-\w.]|(?:%[\da-fA-F]{2}))+/g;
    let url  = ''
    if (urlRegex.test(base64String)) {
      url = base64String;
    } else {
      const blob = base64toBlob(base64String);
      url = URL.createObjectURL(blob);
    }
    
    window.open(url, '_blank');
    setLoading(false);
  };

  const base64toBlob = (data) => {
    // Cut the prefix `data:application/pdf;base64` from the raw base 64
    const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
    const bytes = atob(base64WithoutPrefix);
    let length = bytes.length;
    let out = new Uint8Array(length);
    while (length--) {
        out[length] = bytes.charCodeAt(length);
    }
    return new Blob([out], { type: 'application/pdf' });
  };

  const handleUpdateStatusCheck = async (candidateCheckId, data) => {
    setLoading(true);
    const { data: updatedCheck } = await updateCandidateCheckStatus({ id: candidateCheckId, data });
    setChecks(checks.map(check => check.id === updatedCheck.id ? updatedCheck : check));
    setLoading(false);
  };

  const handleShowNewChecksModal = async () => {
    setLoading(true);
    await dispatch(getForms());
    setShowNewChecksModal(true);
    setLoading(false);
  }

  const handleCreateReferee = async(data) => {
    setLoading(true);
    const { data: referee } = await createReferee(data);
    setReferees([...referees, referee]);
    setLoading(false);
    return referee;
  }

  const handleRefereeUpdate = async(id, data) => {
    setLoading(true);
    const { data: updatedReferee } = await updateReferee({ id, data });
    setReferees(referees.map(referee => referee.id === updatedReferee.id ? updatedReferee : referee))
    setLoading(false);
  }

  const handleRefereeDeleted = async(id) => {
    setLoading(true);
    await deleteReferee(id);
    setReferees(referees.filter(referee => referee.id !== id));
    setLoading(false);    
  }

  const handleRefereeCancelation = async(id) => {
    setLoading(true);
    await updateReferee({ id, data: { canceled: true } });
    setReferees(referees.filter(referee => referee.id !== id));
    setLoading(false);
  }

  return (
    <AppPage loading={loading}>
      <AddCheckModal
        companyChecks={companyChecks}
        forms={forms}
        visible={showNewChecksModal}
        onSubmit={handleNewChecks}
        onClose={() => setShowNewChecksModal(false)}
        className={styles.addModalCheck}
      />
      <IntegrationsModal
        companyIntegrations={company?.integrations?.partners}
        candidate={candidate}
        visible={showIntegrationsModal}
        messagePopUp={onMessagePopUp}
        onClose={() => setShowIntegrationsModal(false)}
      />
      {candidate && (
        <div className={styles.root}>
          {!candidate.completed_at && hasAccess(Permissions.CandidateWrite) &&
            <Alert
              show={showCancelModal}
              title={`Would you like to cancel all ongoing check?`}
              ok="Cancel Check"
              cancel="No Thanks"
              onOk={hanleCandidateCancelation}
              onCancel={() => setShowCancelModal(false)}
            />
          }
          <div className={styles.header}>
            <div className='d-flex justify-content-between align-items-center'>
              <div>
                <p className={'u-padding-right--small u-margin-bottom--small t-small'}>
                  <Link className='u-link dark' to='/dashboard'>DASHBOARD</Link> / CHECK FOR
                </p>
                <div className='d-flex justify-content-start align-items-center'>
                  <h1 className='title-2'>{candidate.name}</h1>
                  <ActionsMenu className={styles.showMedium} small>
                    { hasAccess(Permissions.CandidateWrite) && <ActionsMenu.Item icon='x' title='Cancel ongoing checks' onClick={() => setShowCancelModal((prevState) => !prevState)} disabled={candidate.completed_at} /> }
                    { hasAccess(Permissions.CandidateWrite) && <ActionsMenu.Item icon='archive' title={candidate.archived ? "Restore" : "Archive"} onClick={handleArchiveToggle} /> }
                    { hasAccess(Permissions.CandidateWrite) && <ActionsMenu.Item icon='edit' title='Add note' onClick={() => navigate(`/check/${id}/history/new`)} /> }
                    { hasAccess(Permissions.CandidateWrite) && <ActionsMenu.Item icon='trash-2' title='Delete' onClick={handleCheckDeletion} /> }
                    { hasAccess(Permissions.CandidateChecksResults) && candidate.completed_at && !candidate.canceled && <ActionsMenu.Item icon='download' title='Download All' onClick={handleDownloadAll} /> }
                  </ActionsMenu>
                  <div className={cn(styles.hideMedium, 'd-flex')}>
                    {hasAccess(Permissions.CandidateWrite) &&
                      <IconButton
                        icon="x"
                        small
                        className={cn(styles.icon, 'u-margin-left')}
                        tip="Cancel ongoing checks"
                        onClick={() => setShowCancelModal((prevState) => !prevState)}
                        disabled={candidate.completed_at}
                      />
                    }
                    {hasAccess(Permissions.CandidateWrite) &&
                      <IconButton
                        icon="archive"
                        small
                        className={styles.icon}
                        tip={candidate.archived ? "Restore" : "Archive"}
                        active={candidate.archived}
                        onClick={handleArchiveToggle}
                      />
                    }
                    {hasAccess(Permissions.CandidateWrite) &&
                      <div style={{ position: "relative" }}>
                        <IconButton
                          icon="tag"
                          small
                          className={styles.icon}
                          tip="Tag"
                          onClick={() => setShowTagsMenu(!showTagsMenu)}
                          disabled={tags.length < 1}
                        />
                        <TagsMenu
                          visible={showTagsMenu}
                          onClose={() => setShowTagsMenu(false)}
                          tags={tags}
                          onSelect={handleAddTag}
                          hideDeletion={true}
                          position="bottom"
                        />
                      </div>
                    }
                    {hasAccess(Permissions.CandidateWrite) &&
                      <IconButton
                        icon="edit"
                        small
                        className={styles.icon}
                        tip="Add note"
                        onClick={() => navigate(`/check/${id}/history/new`)}
                      />
                    }
                    <div className={styles.emailLinkContainer}>
                      <IconButton
                        className={styles.icon}
                        icon="link"
                        small
                        tip="Copy candidate email link"
                        onClick={() => setEmailLinkOpened((prevState) => !prevState)}
                      />
                      <CopyEmail
                        open={emailLinkedOpened}
                        emailLink={`${process.env.REACT_APP_WEB_URL}/form_submission/candidate/${candidate.token}`}
                        title="Copy Email Link"
                        setEmailLinkOpened={setEmailLinkOpened}
                      />
                    </div>
                    {hasAccess(Permissions.CandidateWrite) &&
                      <IconButton
                        icon="trash-2"
                        small
                        className={styles.icon}
                        tip="Delete"
                        onClick={handleCheckDeletion}
                      />
                    }
                    {company?.integrations?.enabled && hasAccess(Permissions.CandidateWrite) &&
                      <IconButton
                        icon="git-merge"
                        small
                        className={styles.icon}
                        tip="Integrations"
                        onClick={() => setShowIntegrationsModal(true)}
                      />
                    }
                    {hasAccess(Permissions.CandidateChecksResults) && candidate.completed_at && !candidate.canceled &&
                      <IconButton
                        className={styles.icon}
                        icon="download"
                        small
                        tip="Download All"
                        onClick={handleDownloadAll}
                      />
                    }
                  </div>
                </div>
              </div>
              <div className={cn(styles.hideMobile, 'd-flex', 'align-items-center', 'justify-content-end')}>
                <IconButton
                  icon="arrow-left"
                  className={cn(styles.arrowIcon, {
                    [styles["--disabled"]]: !navigation.previous,
                  })}
                  tip="Previous Check"
                  onClick={() => navigate(`/check/${navigation.previous}/candidate`)}
                  disabled={!navigation.previous}
                />
                <IconButton
                  icon="arrow-right"
                  className={cn(styles.arrowIcon, {
                    [styles["--disabled"]]: !navigation.next,
                  })}
                  tip="Next Check"
                  onClick={() => navigate(`/check/${navigation.next}/candidate`)}
                  disabled={!navigation.next}
                />
              </div>
            </div>
            <div className={cn(styles.candidateDetails, 'd-flex', 'justify-content-start', 'align-items-center', 'u-padding-top', 'u-width-100')}>
              <div className='d-flex justify-content-start align-items-center'>
                <Badge className={`u-status-${candidate.status}`}>
                  {candidate.status}
                </Badge>
                {checks.map((check) => {
                  const checkType = companyChecks.find(companyCheck => companyCheck.type === check.type)?.check_type
                  if(!checkType) return;

                  return (
                    <IconStatusButton
                      key={check.id}
                      className={styles.icon}
                      icon={checkType.icon}
                      prefix="fa"
                      tooltip={`${checkType.title} ${check.status}`}
                      status={check.status}
                      onClick={() => navigate(`/check/${id}/background_checks`)}
                    />
                  );
                })}
              </div>
              <div className={cn(styles.infoFieldRow)}>
                {candidate.reference_code &&
                  <div className={styles.infoField}>
                    <p className={styles.title}>reference code</p>
                    <p className={styles.value}>{candidate.reference_code}</p>
                  </div>
                }
                <div className={styles.infoField}>
                  <p className={styles.title}>role</p>
                  <p className={styles.value}>{candidate.job_role}</p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>department</p>
                  <p className={styles.value}>{candidate.team_name}</p>
                </div>
                {referenceCheck && (
                  <div className={styles.infoField}>
                    <p className={styles.title}>completed</p>
                    <p className={styles.value}>
                      {refereesCompleted} of {referenceCheck.details.referees}{" "}
                      Referees
                    </p>
                  </div>
                )}
                <div className={styles.infoField}>
                  <p className={styles.title}>created on</p>
                  <p className={styles.value}>
                    {candidate.create_date ? (
                      <Moment format={`${dateSlashFormat} @hh:mma`}>
                        {candidate.create_date}
                      </Moment>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>completed on</p>
                  <p className={styles.value}>
                    {candidate.completed_at ? (
                      <Moment format={`${dateSlashFormat} @hh:mma`}>
                        {candidate.completed_at}
                      </Moment>
                    ) : (
                      "-"
                    )}
                  </p>
                </div>
                <div className={styles.infoField}>
                  <p className={styles.title}>time to complete</p>
                  <p className={styles.value}>
                    {candidate.completed_at
                      ? timeDifference(
                        candidate.create_date,
                        candidate.completed_at
                      )
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            <div className={styles.tags}>
              {candidate.tags?.map((tag) => (
                <Badge className={styles.tag} key={tag.id}>
                  {tag.name}
                  <FeatherIcon
                    icon="x"
                    className={styles.tagDeleteBtn}
                    onClick={() => handleTagDeletion(tag.id)}
                  />
                </Badge>
              ))}
            </div>
          </div>
          <Tabs
            id="accountTabs"
            onSelect={(key) => navigate(`/check/${id}/${key}`)}
            activeKey={tab}
            defaultActiveKey="candidate"
            className="tabs"
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Tab eventKey="history" title="History">
              <History
                company={company}
                candidate={candidate}
                referees={referees}
                user={user}
                companyChecks={companyChecks}
                setLoading={setLoading}
                targetNewRecord={target === "new"}
                onCandidateRequest={hanldeSendCandidateRequestEmail}
                onRefereeRequest={hanldeSendRefereeRequestEmail}
                createRecord={addCandidateHistory}
              />
            </Tab>
            <Tab eventKey="candidate" title="Candidate">
              <Candidate
                company={company}
                candidate={candidate}
                teams={teams}
                customFields={customFields}
                handleCandidateUpdate={handleUpdateCandidate}
                sendRequestEmail={hanldeSendCandidateRequestEmail}
                brand={brand}
              />
            </Tab>
            {referenceCheck && (
              <Tab
                eventKey="referees"
                title="Referees"
                disabled={candidate.canceled}
              >
                <Referees
                  check={referenceCheck}
                  candidate={candidate}
                  company={company}
                  referees={referees}
                  setLoading={setLoading}
                  updateReferee={handleRefereeUpdate}
                  sendRequestEmail={hanldeSendRefereeRequestEmail}
                  targetId={target}
                  addReferee={handleCreateReferee}
                  deleteReferee={handleRefereeDeleted}
                  onMessagePopUp={onMessagePopUp}
                />
              </Tab>
            )}
            {referenceCheck &&
              <Tab
                eventKey="reports"
                title="Referee Report"
                disabled={!referenceCheck || !refereesCompleted > 0}
              >
                <Reports
                  check={referenceCheck}
                  referees={referees}
                  candidate={candidate}
                  setLoading={setLoading}
                  getPdfReport={handleGetPdfReport}
                  targetId={target}
                  company={company}
                />
              </Tab>
            }
            {customReferenceCheck && (
              <Tab eventKey="reference" title="Reference Check">
                <Reference
                  company={company}
                  candidate={candidate}
                  check={customReferenceCheck}
                  referees={referees}
                  brand={brand}
                  messagePopUp={onMessagePopUp}
                  addReferee={handleCreateReferee}
                  setLoading={setLoading}
                  getPdfReport={handleGetPdfReport}
                  getCombinedReport={getCombinedReport}
                  updateReferee={handleRefereeUpdate}
                  deleteReferee={handleRefereeDeleted}
                  cancelReferee={handleRefereeCancelation}
                  sendRequestEmail={hanldeSendRefereeRequestEmail}
                  sendReplacementRequestEmail={sendReplacementRequestEmail}
                />
              </Tab>
            )}
            {questionnaireCheck && (
              <Tab eventKey="questionnaire" title="Questionnaire">
                <Questionnaire
                  check={questionnaireCheck}
                  candidate={candidate}
                  setLoading={setLoading}
                />
              </Tab>
            )}
            <Tab eventKey="background_checks" title="Background Checks">
              <Checks
                company={company}
                candidate={candidate}
                checks={checks}
                companyChecks={companyChecks}
                setLoading={setLoading}
                showNewChecksModal={handleShowNewChecksModal}
                handleCancelCheck={handleCancelCheck}
                handleUpdateStatus={handleUpdateStatusCheck}
                handleCandidateCheckViewResult={handleCandidateCheckViewResult}
              />
            </Tab>
          </Tabs>
        </div>
      )}
    </AppPage>
  );
};

export default Check
