import { useEffect, useState } from 'react';
import CandidatePage from 'layouts/CandidatePage/CandidatePage';
import { CandidateCheckScreens } from 'constants/candidate_screens';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import styles from './CandidateIds.module.scss';
import { CustomButton } from '../components/CustomButton/CustomButton';
import cn from 'classnames';
import { ID_TYPES } from 'helpers/checks';
import { UploadIdModal } from '../components/UploadIdModal/UploadIdModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectCandidateApplication } from 'api/candidate-application-slice';
import { useCandidateApplicationUploadActions } from 'hooks/upload-actions';
import { intersection } from 'helpers/arrays';

const CandidateIds = () => {

  const navigate = useNavigate();
  
  const { token } = useParams();

  const { application: { candidate, brand, uploads }, ids } = useSelector(selectCandidateApplication);

  const { signUpload, createUpload, deleteUpload } = useCandidateApplicationUploadActions(token);

  // All batches. E.g. [ { ids: ['driver_license', 'card_18'], batches: [ ['driver_license', 'id'], ['card_18'] ] }]
  const [idsBatches, setIdsBatches] = useState([]);
  // Current batch. E.g. ['driver_license', 'card_18']
  const [idsBatch, setIdsBatch] = useState();

  const [currentBatchIndex, setCurrentBatchIndex] = useState(0);

  const [showUploadIdModal, setShowUploadIdModal] = useState(false);
  const [selectedIdType, setSelectedIdType] = useState({});


  // Set batches
  useEffect(() => {
    if(!ids) return;

    // Get required batches
    let preliminarIdsBatches = [];
    ids.checks.forEach(checkType => {
      // Loop check id batches
      checkType.id_types.forEach(checkIdBatch => {
        // Loop id batches
        let notEmptyIntersectionIndex = null;
        for (let i = 0; i < preliminarIdsBatches.length; i++) {
          const ids = preliminarIdsBatches[i].ids;
          if (intersection(ids, checkIdBatch).length > 0) {
            notEmptyIntersectionIndex = i;
            break;
          }
        }

        // if intersection possible, else add new batch 
        if (notEmptyIntersectionIndex !== null) {
          preliminarIdsBatches[notEmptyIntersectionIndex].ids = intersection(checkIdBatch, preliminarIdsBatches[notEmptyIntersectionIndex].ids)
          preliminarIdsBatches[notEmptyIntersectionIndex].batches.push(checkIdBatch);
        } else {
          preliminarIdsBatches.push({ ids: checkIdBatch, batches: [checkIdBatch], completed: false });
        }
      });
    });

    if(uploads.length < 0) {
      setIdsBatches(preliminarIdsBatches);
    } else {
      // Check completed batches
      const uploadTypes = uploads?.map(u => u.metadata.id_type);

      let idsBatches = [];
      preliminarIdsBatches.forEach(idsBatch => {
        const completed = idsBatch.ids.filter(type => uploadTypes.includes(type)).length > 0;
        if(completed) {
          idsBatches.push({ ...idsBatch, completed: true })
        } else {
          // Try to remove individually completed batches
          let completedBatches = [];
          let inclompletedBatches = [];
          idsBatch.batches.forEach(batch => {
            if(batch.filter(type => uploadTypes.includes(type)).length > 0) 
              completedBatches.push(batch);
            else 
              inclompletedBatches.push(batch);
          });

          // Split completed batches
          if(completedBatches.length > 0) {
            completedBatches.forEach(completedBatch => {
              idsBatches.unshift({ ids: completedBatch, batches: [completedBatch], completed: true })
            });
          }

          // Intersect icomplete batches
          if(inclompletedBatches.length > 0) {
            const incompletedIds = inclompletedBatches.reduce((acc, curr) => intersection(acc, curr));
            idsBatches.push({ ids: incompletedIds, batches: inclompletedBatches, completed: false })
          }
        }
      });

      setIdsBatches(idsBatches);
    }
  }, [ids?.checks.length])


  // Verify completion when uploading an ID
  useEffect(() => {
    if(idsBatches.length < 1 || uploads.length < 1 ) return;
    
    const uploadTypes = uploads?.map(u => u.metadata.id_type);

    setIdsBatches(idsBatches.map(idsBatch => 
      ({...idsBatch, completed: idsBatch.ids.filter(type => uploadTypes.includes(type)).length > 0 })
    ));
  }, [uploads?.length])


  // Set initial batch
  useEffect(() => {
    if(idsBatches.length < 0) return;

    let index = idsBatches.findIndex(idsBatch => idsBatch.completed == false);
    if(index < 0) index = idsBatches.length - 1;

    setIdsBatch(idsBatches[index]);
    setCurrentBatchIndex(index);
  }, [idsBatches.length])

  // // Next batch
  const nextBatch = () => {
    if(idsBatches.length - 1 == currentBatchIndex) {
      navigate(`/form_submission/candidate/${CandidateCheckScreens.MENU}/${token}`);
    } else {
      const newIndex = currentBatchIndex + 1;
      setCurrentBatchIndex(newIndex);
      setIdsBatch(idsBatches[newIndex]);
    }
  }

  // // Split merged batch is possible
  const splitBatch = () => {
    const singleBatch = { ids: idsBatch.batches[0], batches: [idsBatch.batches[0]], completed: false }
    const legacyBatch = { ids: idsBatch.batches[0].slice(1), batches: [idsBatch.batches.slice(1)], completed: false }

    setIdsBatches([...idsBatches.slice(0, currentBatchIndex), singleBatch, legacyBatch, ...idsBatches.slice(currentBatchIndex + 1)])
    setIdsBatch(singleBatch);
  }

  const onIdTypeSelected = (idType) => {
    setSelectedIdType(idType);
    setShowUploadIdModal(true);
  }

  return ( 
    <CandidatePage token={token} withTopLogo brand={brand}>
      <CandidatePage.View>
        <UploadIdModal 
          brand={brand}
          candidate={candidate}
          selectedIdType={selectedIdType}
          visible={showUploadIdModal}
          uploads={uploads}
          onClose={() => setShowUploadIdModal(false)}
          signUpload={signUpload}
          onUpload={createUpload}
          onDelete={deleteUpload}
        />
        <CandidatePage.Card className={styles.root}>
          <img src='assets/images/icons/id_icon.svg' className={styles.icon} />
          <h1 className="title-4 u-padding-top u-padding-bottom--small">Upload your ID</h1>
          <span className="u-margin-bottom t-small">
            On this page we'll ask you to upload a copy or photograph of at least one of the following identifications.
          </span>

          <div className={cn(styles.stepsWrapper, 'u-margin-bottom')}>
            {idsBatches && Array.from(Array(idsBatches.length).keys()).map(index => (
              <span
                key={index}
                style={ index <= currentBatchIndex ? { backgroundColor: brand.color_button, color: '#FFF', border: 'none' } : {} }
                className={cn(styles.step)}
              >
                {index + 1}
              </span>
            ))}
          </div>

          <div className={styles.idsWrapper}>
            {idsBatch?.ids.map(idOption => {
              const option = ID_TYPES[idOption];

              return(
                <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={<Tooltip>{option.title}</Tooltip>}>
                  <div>
                    <CustomButton 
                      key={option.title} 
                      small
                      type="secondary" 
                      brand={brand}
                      className={styles.idButton}
                      onClick={() => onIdTypeSelected(option)} 
                      completed={uploads.find(u => u.metadata.id_type === idOption)}
                    >
                      <span className={styles.idButtonText}>{option.title}</span>
                    </CustomButton>
                  </div>
                </OverlayTrigger>
              )
            })}

            {idsBatch?.batches.length > 1 &&
              <OverlayTrigger trigger={['hover', 'focus']} placement='top' overlay={<Tooltip>Use a different ID</Tooltip>}>
                <div>
                  <CustomButton small type="secondary" brand={brand} className={styles.idButton} onClick={splitBatch}>
                    <span className={styles.idButtonText}>Use a different ID</span>
                  </CustomButton>
                </div>
              </OverlayTrigger>
            }
          </div>
          
          <CustomButton brand={brand} className='u-margin-top--large' onClick={nextBatch} small>
            {idsBatches.length - 1 === currentBatchIndex ? 'Finish' : 'Next'}
          </CustomButton>
        </CandidatePage.Card>
      </CandidatePage.View>
    </CandidatePage>
  )
}

export default CandidateIds;