import styles from './Loader.module.scss';
import cn from 'classnames';

export const Loader = ({ className }) => (
  <div className={cn(styles.container, { [className]: className })}>
    <div className={styles.root}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
)
