import { useEffect, useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import Moment from 'react-moment';
import { IconButton } from 'components/IconButton/IconButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox } from 'components/FormComponents';
import { useLocale } from 'hooks/locale';
import styles from './CheckTableRow.module.scss';
import { Link } from 'react-router-dom';
import cn from 'classnames';


export const CheckTableRow = ({
  bulkSelect,
  company,
  candidate,
  companyChecks,
  onToggledSelection = () => {},
  onDelete = () => {},
  onArchiveChange = () => {},
}) => {

  const [selected, setSelected] = useState(false);
  const { dateSlashFormat } = useLocale(company?.locale);

  // Row selection
  useEffect(() => { 
    setSelected(bulkSelect);
    onToggledSelection(candidate.id, bulkSelect);
    return () => { onToggledSelection(candidate.id, false) }
  }, [bulkSelect]);


  const handleDelete = (e) => {
    onDelete(candidate.id);
  }


  const handleArchiveChange = (e) => {
    onArchiveChange(candidate);
  }

  const approval_status_tooltip = (user_review_status, check_status) => {
    switch (user_review_status) {
      case 'approved':
        return ` (${check_status}, approved)`;
      case 'not_approved':
        return ` (${check_status}, not approved)`;
      case 'needs_review':
        return ` (${check_status}, needs review)`;
      default:
        return ` (${check_status})`;
    }
  }

  const approval_status_icon = (user_review_status) => {
    switch (user_review_status) {
      case 'approved':
        return <FontAwesomeIcon icon="check-circle" className={cn(styles.approvalIcon_Approved, styles.approvalIcon)} />;
      case 'not_approved':
        return <FontAwesomeIcon icon="exclamation-triangle" className={cn(styles.approvalIcon_NotApproved, styles.approvalIcon)}/>;
      case 'needs_review':
        return <FontAwesomeIcon icon="exclamation-triangle" className={cn(styles.approvalIcon_NeedReview, styles.approvalIcon)}/>;
      default:
        return "";
    }
  }

  const statusIcons = () => {
    let icons = [];

    candidate.checks_statuses?.forEach(check_status => {
      const checkType = companyChecks.find(companyCheck => companyCheck.type === check_status.type)?.check_type;
      if(!checkType) return;

      if((check_status.type === 'reference' || check_status.type === 'custom_reference') && candidate.referees_statuses) {
        candidate.referees_statuses.forEach(referee => {
          icons.push(
            <OverlayTrigger key={referee.id} overlay={<Tooltip id={referee.id}>{referee.name} Referee {approval_status_tooltip(check_status.user_review_status, check_status.status)}</Tooltip>}>
              <div className={cn(styles.userIcon, `u-txt-status-${referee.status}`)}>
                {approval_status_icon(check_status.user_review_status)}
                {(referee.warning_flag === 1 && check_status.status === 'completed') && 
                  <FontAwesomeIcon icon="exclamation-triangle" className={styles.resultIcon} />
                }
                <Link to={`/check/${candidate.id}/${checkType.candidate_tab}/${referee.id}`}><FontAwesomeIcon className={`u-txt-status-${referee.status}`} icon="user" /></Link>
              </div>
            </OverlayTrigger>
          );
        });
      } else {
        icons.push(
          <OverlayTrigger key={check_status.id} overlay={<Tooltip id={check_status.id}>{checkType.title} {approval_status_tooltip(check_status.user_review_status, check_status.status)}</Tooltip>}>
            <div className={cn(styles.userIcon, `u-txt-status-${check_status.status}`)}>
              {approval_status_icon(check_status.user_review_status)}
              {(check_status.result_flag === 1 && check_status.status === 'completed') && <FontAwesomeIcon icon="exclamation-triangle" className={styles.resultIcon} />}
              <Link to={`/check/${candidate.id}/${checkType.candidate_tab}`} ><FontAwesomeIcon className={`u-txt-status-${check_status.status}`} icon={checkType.icon} /></Link> 
            </div>
          </OverlayTrigger>
        )
      } 
    });

    return icons;
  }

  return ( 
    <div className={styles.root}>
      <div className={cn(styles.checkRow)}>
        <Checkbox 
          className={styles.checkbox}
          checked={selected} 
          onClick={selected => {
            setSelected(selected);
            onToggledSelection(candidate.id, selected);
          }}
        />
        <div className={styles.column}>
          <Badge className={`u-status-${candidate.status}`}>{candidate.status}</Badge>
        </div>
        <div className={styles.column}>
          <Link to={`/check/${candidate.id}`} className='u-link dark'>{candidate.name}</Link>
        </div>
        <div className={cn(styles.column, "sm-hide")}><p className="u-text-ellipsis u-no-margin">{candidate.team_name}</p> {statusIcons()}</div>
        <div className={cn(styles.column, "sm-hide")}><p className="u-text-ellipsis u-no-margin">{candidate.job_role + (candidate.reference_code ? ` - ${candidate.reference_code}` : '')}</p></div>
        <div className={cn(styles.column, 'u-flex-box', 'u-flex-justify-between')}>
          <Moment format={dateSlashFormat}>{candidate.create_date}</Moment>
          <div className={cn(styles.iconsContainer, "xs-hide")}>
            <Link to={`/check/${candidate.id}/background_checks`}><IconButton className='u-margin-right--small' icon='check-square' tip='View report' /></Link>
            <IconButton className='u-margin-right--small' icon='archive' tip={candidate.archived ? 'Restore' : 'Archive'} active={candidate.archived} onClick={handleArchiveChange} />
            <IconButton icon='trash-2' tip='Delete' onClick={handleDelete}/>
          </div>
        </div>
      </div>
    </div>
  )

} 