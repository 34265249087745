import { AnimatedModal } from 'components/AnimatedModal/AnimatedModal';
import { useForm, Controller } from 'react-hook-form';
import { Button, FilePicker, InputGroup } from 'components/FormComponents';
import { useApiUploadActions } from 'hooks/upload-actions';
import styles from './AiReferenceModal.module.scss';


export const AiReferenceModal = ({
  visible,
  onClose = () => { },
  handleUpload = () => { },
}) => {

  const { getUpload, signUpload, createUpload, deleteUpload } = useApiUploadActions();

  const { handleSubmit, control } = useForm();

  return (
    <AnimatedModal title="Checkmate AI Reference Generator" visible={visible} noClose className={styles.root}>
      {visible &&
        <form onSubmit={handleSubmit(handleUpload)}>
          <InputGroup>
            <p className='t-small u-margin-bottom--large u-text-centered'>Upload your job description and we'll use AI to create a custom refrence check for you to review</p>
            <Controller
              control={control}
              name='upload_id'
              render={({ field: { onChange, value } }) => (
                <FilePicker
                  accept={{ 'application/pdf': ['.pdf'], 'application/msword': ['.doc', '.docx'] }}
                  onChange={onChange}
                  value={value}
                  bucketFolder="reference_job"
                  getUpload={getUpload}
                  signUpload={signUpload}
                  createUpload={createUpload}
                  deleteUpload={deleteUpload}
                />
              )}
            />
          </InputGroup>
          <div className="d-flex u-width-100 justify-content-end u-margin-bottom--large">
            <Button type='secondary' onClick={() => onClose(false)} className='u-width-100 u-margin-top u-margin-right'>Cancel</Button>
            <Button submit icon="search" className='u-width-100 u-margin-top'>Analyse Job Description</Button>
          </div>
        </form>
      }
    </AnimatedModal>
  )
}