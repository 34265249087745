import moment, { months } from 'moment';


//"01-05-1993 06:00:00", "03-05-1993 09:00:00" to "2days 3hours"
export function timeDifference(date_from, date_to){
  var dateFrom = moment(date_from);
  var dateTo = moment(date_to);
  var days = dateTo.diff(dateFrom, 'days');
  var hours = dateTo.diff(dateFrom, 'hours') - (days * 24);
  var minutes = dateTo.diff(dateFrom, 'minutes') - (hours * 60);
  if(days > 0)
    return `${days}days ${hours}hours`
  else if (hours > 0)
    return `${hours}hours ${minutes}mins`
  else
    return `${minutes}mins`
}

//"2013-02-04T18:35:24+00:00"
export function now() {
  return moment().utc().format();
}

// "2021_01_04"
export const stringDate = () => {
  const now = new Date();
  const date = now.getDate() > 10 ? now.getDate() : '0' + now.getDate(); // dd
  const month = now.getMonth() > 9 ? (now.getMonth() + 1) : '0' + ( now.getMonth() + 1 ); // mm
  const year = now.getFullYear(); // yyyy

  return `${year}_${month}_${date}`;
}