import styles from './Checks.module.scss';
import { Button } from 'components/FormComponents';
import { CheckStatusRow } from 'components/CheckStatusRow/CheckStatusRow';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import { saveAs } from 'file-saver';
import { Permissions } from 'constants/permissions';
import { useAccessControl } from 'hooks/access-control';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { selectPermissions } from 'api/user-permissions-slice';
import { useLazyGetCandidateUploadsQuery } from 'api/candidates-api';
import { useLazyGetCandidateCheckResultQuery } from 'api/candidate-checks-api';
import { useLazyGetSubCheckResultQuery } from 'api/sub-checks-api';

export const Checks = ({
  candidate,
  company,
  companyChecks,
  checks = [],
  setLoading              = () => {},
  showNewChecksModal      = () => {},
  handleCancelCheck       = () => {},
  handleUpdateStatus      = () => {},
  handleCandidateCheckViewResult = () => {},
}) => {

  const permissions = useSelector(selectPermissions);
  const hasAccess = useAccessControl(permissions);

  const [getCandidateCheckResult] = useLazyGetCandidateCheckResultQuery();
  const [getSubCheckResult] = useLazyGetSubCheckResultQuery();

  const [getUploads] = useLazyGetCandidateUploadsQuery();

  const downloadUpload = (upload) => {
    return new Promise((resolve, reject) => {
      JSZipUtils.getBinaryContent(upload.url,
        (error, data) => {
          if (error) reject(error);
          else resolve({ file_name: upload.file_name, data });
        }
      )
    });
  }

  const handleDownloadIds = async () => {
    setLoading(true);
    var zip = new JSZip();
    let promises = [];
    const uploads = await getUploads({ id: candidate.id }).unwrap();
    uploads.forEach(upload => {
      if(upload.metadata?.id_type || upload.metadata?.virtual_id) promises.push(downloadUpload(upload));
    });

    const results = await Promise.all(promises);
    results.forEach((result, index) => {
      zip.file(`${index + 1}. ${result.file_name}`, result.data, { binary: true });
    });
    const content = await zip.generateAsync({ type:"blob" })
    saveAs(content, `${candidate.name} ids.zip`);
    setLoading(false);
  }

  return (
    <div className={styles.root}>
      <div className={cn('d-flex', 'u-width-100', 'justify-content-end', 'u-margin-bottom--large')}>
        <Button type="outline" className="u-margin-right" onClick={handleDownloadIds}>ID Documents</Button>
        {hasAccess(Permissions.CandidateChecksResults) && 
          <Button onClick={showNewChecksModal}>Add Check</Button>
        }
      </div>
      <div>
        {checks.map(check => {
          const checkType = companyChecks.find(companyCheck => companyCheck.type === check.type)?.check_type;
          if(!checkType) return;

          return (
            <CheckStatusRow
              key={check.id}
              candidate={candidate}
              checkType={checkType}
              permissions={permissions}
              company={company}
              check={check}
              setLoading={setLoading}
              handleUpdateStatus={handleUpdateStatus}
              getCandidateCheckResult={getCandidateCheckResult}
              getSubCheckResult={getSubCheckResult}
              handleCancelCheck={handleCancelCheck}
              handleCandidateCheckViewResult={handleCandidateCheckViewResult}
            />
          )
        })}
      </div>
    </div>
  )

}