import styles from './AdminCandidate.module.scss';
import AdminPage from 'layouts/AdminPage/AdminPage';
import { RowDetails } from 'components/RowDetails/RowDetails';
import { 
  useRejectCandidateChecksValuesMutation,
  useUpdateCandidateChecksValuesMutation
} from 'api/candidate-checks-values-api';
import { 
  useGetCandidateCompanyQuery,
  useGetCandidateQuery,
  useGetCandidateChecksQuery,
  useGetCandidateChecksValuesQuery,
  useGetCandidateUploadsQuery,
  useUpdateCandidateMutation,
  useCancelCandidateChecksMutation,
  useAddCandidateNoteMutation,
  useAddCandidateNotificationMutation
} from 'api/candidates-api';
import { loginAs } from 'api/authentication-slice';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { CandidateCheckDetails } from './components/CandidateCheckDetails/CandidateCheckDetails';
import { FilePreview } from './components/FilePreview/FilePreview';
import { CandidateCheckValues } from './components/CandidateCheckValues/CandidateCheckValues';
import FeatherIcon from 'feather-icons-react';
import { Collapse } from 'react-bootstrap';
import Alert from 'components/Alert/Alert';
import cookie from 'react-cookies';
import cn from 'classnames';
import Moment from 'react-moment';
import { RejectModal } from './components/RejectModal/RejectModal';
import { useDocumentTitle } from 'hooks/document-title';
import { ActionsMenu } from 'components/ActionsMenu/ActionsMenu';
import { useGetCheckTypesQuery } from 'api/check-types-api';
import { useLocale } from 'hooks/locale';
import { useDispatch } from 'react-redux';
import { AddNoteModal } from './components/AddNoteModal/AddNoteModal';
import { AddNotificationModal } from './components/AddNotificationModal/AddNotificationModal';
import { CandidateUpdateModal } from './components/CandidateUpdateModal/CandidateUpdateModal';

const AdminCandidate = () => {

  let { candidate_id: candidateId } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: company } = useGetCandidateCompanyQuery(candidateId);
  const { data: candidateData } = useGetCandidateQuery(candidateId);
  const { data: checksData, isLoading: loadingChecks } = useGetCandidateChecksQuery(candidateId);
  const { data: checkTypes } = useGetCheckTypesQuery();
  const { data: checkValuesData } = useGetCandidateChecksValuesQuery(candidateId);
  const { data: uploadsData } = useGetCandidateUploadsQuery({ id: candidateId, params: { with_metadata: true, no_results: true }})

  const [rejectCandidateChecksValues] = useRejectCandidateChecksValuesMutation()
  const [updateCandidateChecksValues] = useUpdateCandidateChecksValuesMutation()
  const [updateCandidate] = useUpdateCandidateMutation()
  const [cancelCandidateChecks] = useCancelCandidateChecksMutation()
  const [addCandidateNote] = useAddCandidateNoteMutation()
  const [addCandidateNotification] = useAddCandidateNotificationMutation()

  const [loading, setLoading] = useState(false);

  const [candidateValuesOpened, setCandidateValuesOpened] = useState(true);
  const [uploadsOpened, setUploadsOpened] = useState(true);
  const [rejectedUploadsOpened, setRejectedUploadsOpened] = useState(true);

  const [cancelConfirmation, setCancelConfirmation] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [restoreConfirmation, setRestoreConfirmation] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [addNoteModal, setAddNoteModal] = useState(false);
  const [addNotificationModal, setAddNotificationModal] = useState(false);
  const [updateCandidateModal, setUpdateCandidateModal] = useState(false);

  const [candidate, setCandidate] = useState();
  const [checks, setChecks] = useState([]);
  const [checkValues, setCheckValues] = useState([]);
  const [uploads, setUploads] = useState([]); 
  const [rejectedUploads, setRejectedUploads] = useState([]);

  const { dateSlashFormat } = useLocale(company?.locale);

  useDocumentTitle(candidate ? candidate?.name : 'Loading...');

  useEffect(() => { if(candidateData) setCandidate(candidateData) }, [candidateData])
  useEffect(() => { if(checkValuesData) setCheckValues(checkValuesData) }, [checkValuesData])
  useEffect(() => { if(checksData) setChecks(checksData) }, [checksData])
  useEffect(() => {
    if(!uploadsData || !checkValuesData) return;

    setUploads(uploadsData.filter(upload => !checkValuesData?.rejected_values?.files?.includes(upload.id)));
    setRejectedUploads(uploadsData.filter(upload => checkValuesData?.rejected_values?.files?.includes(upload.id)));
  }, [uploadsData, checkValuesData])

  // Cancel
  const cancelCheck = async() => {
    setLoading(true);
    setCancelConfirmation(false);
    const { data: result } = await cancelCandidateChecks(candidateId);
    setCandidate(result);
    setLoading(false);
  }

  // Reject
  const handleReject = async(fields) => {
    setLoading(true);
    const { data: result } = await rejectCandidateChecksValues({ candidate_id: candidateId, rejected_fields: fields });
    setCheckValues(result);
    setLoading(false);
  }

  // Update
  const handleUpdate = async(data) => {
    setLoading(true);
    const { data: result } = await updateCandidate({ id: candidateId, data });
    setCandidate(result);
    setLoading(false);
  }

  // Update candidate checks values
  const handleUpdateCandidateChecksValues = async(data) => {
    setLoading(true);
    const { data: result } = await updateCandidateChecksValues({ candidate_id: candidateId, values: data });
    setCheckValues(result);
    setLoading(false);
  }

  // Login as candidate user
  const logIntoCandidate = async() => {
    setLoading(true);
    const { payload: result } = await dispatch(loginAs(candidate.user_id));
    cookie.save('admin-token', cookie.load('token'), { path: '/' });
    cookie.save('admin-route', `/admin/candidates/${candidate.id}`, { path: '/' });
    cookie.save('token', result.token, { path: '/' });
    setLoading(false);
    navigate(`/check/${candidate.id}/background_checks`);
  }

  // Update check data
  const addOrUpdateCheck = (check) => {
    if(!check?.id) return;

    if(checks.findIndex(c => c.id === check.id) >= 0)
      setChecks(curr => curr.map(c => c.id === check.id ? check : c));
    else
      setChecks(curr => [...curr, check]);
  }

  // Restore candidate
  const restoreCandidate = () => {
    setRestoreConfirmation(false);
    handleUpdate({ deleted: false })
  }

  // Delete candidate
  const deleteCandidate = () => {
    setDeleteConfirmation(false);
    handleUpdate({ deleted: true })
  }

  // Update candidate details
  const updateCandidateDetails = (data) => {
    setUpdateCandidateModal(false);
    handleUpdate(data)
  }

  // Add candidate note
  const handleAddCandidateNote = async({ message }) => {
    setLoading(true);
    setAddNoteModal(false);
    await addCandidateNote({ id: candidateId, message })
    setLoading(false);
  }

  // Add candidate note
  const handleAddCandidateNotification = async(params) => {
    setLoading(true);
    await addCandidateNotification({ id: candidateId, params });
    setLoading(false);
    setAddNotificationModal(false);
  }

  return (
    <AdminPage loading={loading || loadingChecks} title={candidate?.name}>
      <Alert  
        show={cancelConfirmation}
        title={`Are you sure you want to cancel ${candidate?.first_name} and all it's checks?`}
        ok='Yes' 
        cancel='No'
        onOk={cancelCheck}
        onCancel={() => setCancelConfirmation(false)}
      />

      <Alert
        show={deleteConfirmation}
        title={`Are you sure you want to delete ${candidate?.first_name}?`}
        ok='Delete'
        onOk={deleteCandidate}
        cancel='Cancel'
        onCancel={() => setDeleteConfirmation(false)}
      />

      <Alert
        show={restoreConfirmation}
        title={`Are you sure you want to restore ${candidate?.first_name}?`}
        ok='Restore'
        onOk={restoreCandidate}
        cancel='Cancel' 
        onCancel={() => setRestoreConfirmation(false)}
      />

      <RejectModal 
        visible={rejectModal}
        checkValues={checkValues?.values}
        uploads={uploads}
        onClose={() => setRejectModal(false)}
        onReject={handleReject}
      />

      <AddNoteModal 
        visible={addNoteModal}
        onSubmit={handleAddCandidateNote}
        onClose={() => setAddNoteModal(false)}
      />

      <AddNotificationModal 
        visible={addNotificationModal}
        onSubmit={handleAddCandidateNotification}
        onClose={() => setAddNotificationModal(false)}
      />

      <CandidateUpdateModal 
        candidate={candidate}
        visible={updateCandidateModal}
        onSubmit={updateCandidateDetails}
        onClose={() => setUpdateCandidateModal(false)}
      />

      <RowDetails className={cn(styles.details, 'u-divider-bottom-border')}>
        <RowDetails.Status status={candidate?.status} />
        <RowDetails.Column title='ID' data={candidate?.id} />
        <RowDetails.Column title='Email' data={candidate?.email} />
        <RowDetails.Column title='Company' data={company?.name} />
        <RowDetails.Column title='Department' data={candidate?.team_name} />
        <RowDetails.Column title='Job Role' data={candidate?.job_role} />
        <RowDetails.Column title='Created on' data={<Moment format={dateSlashFormat}>{candidate?.create_date}</Moment>} />
        <RowDetails.Column title='Submitted at' data={<Moment format={dateSlashFormat}>{candidate?.submitted_at}</Moment>} />
        <RowDetails.Column title='Completed on' data={candidate?.completed_at ? <Moment format={dateSlashFormat}>{candidate?.completed_at}</Moment> : '-'} />
        <RowDetails.Column title='Data deleted at' data={candidate?.data_deleted_at ? <Moment format={dateSlashFormat}>{candidate?.data_deleted_at}</Moment> : '-'} />
        <ActionsMenu>
          <ActionsMenu.Item icon='settings' title='Update candidate details' onClick={() => setUpdateCandidateModal(true)} />
          <ActionsMenu.Item icon='x' title='Cancel candidate' onClick={() => setCancelConfirmation(true)} />
          <ActionsMenu.Item icon='user-x' title='Decline Background Details' onClick={() => setRejectModal(true)} />
          <ActionsMenu.Item icon='key' title='Login into candidate' onClick={logIntoCandidate} />
          {candidate?.deleted ?
            <ActionsMenu.Item icon='refresh-ccw' title='Restore' onClick={() => setRestoreConfirmation(true)} /> :
            <ActionsMenu.Item icon='trash' title='Delete' onClick={() => setDeleteConfirmation(true)} />
          }
          <ActionsMenu.Item icon='external-link' title='Open candidate link' onClick={() => window.open(`${process.env.REACT_APP_WEB_URL}/form_submission/candidate/menu/${candidate.token}`, '_blank')} />
          <ActionsMenu.Item icon='external-link' title='Open partner link' onClick={() => window.open(candidate.partner_link, '_blank')} disabled={!candidate?.partner_link} />
          <ActionsMenu.Item icon='edit' title='Add note' onClick={() => setAddNoteModal(true)} />
          <ActionsMenu.Item icon='mail' title='Send Email' onClick={() => setAddNotificationModal(true)}/>
        </ActionsMenu>
      </RowDetails>
      
      <div className={styles.section}>
        <div className={styles.checks}>
          {checks.map(check => {
            const checkType = checkTypes?.find(checkType => checkType.slug === check.type);

            return (
              <CandidateCheckDetails 
                key={check.id}
                candidate={candidate}
                check={check}
                checkType={checkType}
                setLoading={setLoading}
                addOrUpdateCheck={addOrUpdateCheck}
              />
            )
          })}
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles.values}>
          {checkValues && 
            <div className={styles.section}>
              <h1 className={styles.title} onClick={() => setCandidateValuesOpened(!candidateValuesOpened)}>
                Candidate Values
                <FeatherIcon icon='chevron-down' className={cn(styles.toogleButton, { [styles.toogleButtonOpened]: candidateValuesOpened })} />
              </h1>
              <Collapse in={candidateValuesOpened}>
                <div>
                  <CandidateCheckValues 
                    checkValues={checkValues}
                    updateCandidateChecksValues={handleUpdateCandidateChecksValues}
                  />
                </div>
              </Collapse>
            </div>
          }
        </div>
        <div className={styles.files}>
          {rejectedUploads.length > 0 &&
            <div className={styles.section}>
              <h1 className={styles.title} onClick={() => setRejectedUploadsOpened(!rejectedUploadsOpened)}>
                Rejected Uploads
                <FeatherIcon icon='chevron-down' className={cn(styles.toogleButton, { [styles.toogleButtonOpened]: rejectedUploadsOpened })} />
              </h1>

              <Collapse in={rejectedUploadsOpened}>
                <div>
                  {rejectedUploads.map(upload => (
                    <FilePreview key={upload.id} file={upload} rejected />
                  ))}
                </div>
              </Collapse>
            </div>
          }
          {uploads.length > 0 &&
            <div className={styles.section}>
              <h1 className={styles.title} onClick={() => setUploadsOpened(!uploadsOpened)}>
                Candidate Uploads
                <FeatherIcon icon='chevron-down' className={cn(styles.toogleButton, { [styles.toogleButtonOpened]: uploadsOpened })} />
              </h1>
              <Collapse in={uploadsOpened}>
                <div>
                  {uploads.map(upload => (
                    <FilePreview key={upload.id} file={upload} />
                  ))}
                </div>
              </Collapse>
            </div>
          }
        </div>
      </div>
    </AdminPage>
  )
}

export default AdminCandidate