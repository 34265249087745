import { useState, useEffect } from 'react';
import Alert from 'components/Alert/Alert';
import { Input, PhoneNumberPicker, InputGroup, Button, Select } from 'components/FormComponents';
import { IconButton } from 'components/IconButton/IconButton';
import cn from 'classnames';
import { CustomFields } from 'views/Account/tabs/CustomFields/components/CustomFields/CustomFields';
import { useForm, Controller, useWatch } from 'react-hook-form';


export const Candidate = ({
  candidate,
  company,
  teams = [],
  customFields,
  brand,
  handleCandidateUpdate = () => { },
  sendRequestEmail = () => { }
}) => {

  const { register, watch, reset, control, handleSubmit } = useForm({ defaultValues: candidate });

  const [showRequestAlert, setShowRequestAlert] = useState(false);

  const team_id = useWatch({ control, name: 'team_id' });

  // Reset candidate details
  useEffect(() => reset(candidate), [candidate])

  const onSubmit = async (data) => {
    await handleCandidateUpdate(data);
    setShowRequestAlert(!candidate.completed_at && !candidate.canceled);
  }

  const sendNewRequest = () => {
    setShowRequestAlert(false);
    sendRequestEmail();
  }

  return (
    <div className={cn('card', 'card-with-border', 'card--small')}>
      <Alert
        show={showRequestAlert}
        title="Would you like to send a new request to the candidate?"
        ok="Send New Request"
        onOk={sendNewRequest}
        cancel="No Thanks"
        onCancel={() => setShowRequestAlert(false)}
      />
      <form className='u-padding' onSubmit={handleSubmit(onSubmit)}>
        <div className={cn('d-flex', 'justify-content-between', 'align-items-center', 'u-padding')}>
          <div>
            <div className='title-3'>Candidate Information</div>
            <div className='t-small'>View and update details about the candidate</div>
          </div>
          {!candidate.completed_at && !candidate.canceled &&
            <IconButton icon='send' tip='Send new request' onClick={() => setShowRequestAlert(true)} disabled={candidate.partner_application_id && !candidate.partner_application_started} />
          }
        </div>
        <hr className='divider u-margin-0' />
        <div className="u-padding">
          <div className="inline u-margin-top--large">
            <InputGroup title='First name'>
              <Input name='first_name' register={register} validators={{ required: true }} placeholder='John' />
            </InputGroup>
            <InputGroup title='Last name'>
              <Input name='last_name' register={register} validators={{ required: true }} placeholder='Doe' />
            </InputGroup>
          </div>
          <InputGroup title='Email'>
            <Input name='email' register={register} validators={{ required: true }} placeholder='john@checkmate.com' />
          </InputGroup>
          <InputGroup title='Contact Number'>
            <Controller
              control={control}
              name='contact_number'
              render={({ field: { onChange, value } }) => (
                <PhoneNumberPicker value={value} onChange={onChange} defaultCountry={company?.country} />
              )}
            />
          </InputGroup>
          <InputGroup title='Job title'>
            <Input name='job_role' register={register} placeholder='General Manager' />
          </InputGroup>
          <InputGroup title='Department'>
            <Input name='team_name' register={register} placeholder='Department' />
          </InputGroup>

          {candidate.cc_notification_emails &&
            <InputGroup title='CC Notification Emails'>
              <Input name='cc_notification_emails' register={register} />
            </InputGroup>
          }

          <InputGroup title='Brand'>
            <Input disabled={true} inputProps={{ value: brand?.name }} />
          </InputGroup>

          {teams.length > 1 &&
            <InputGroup title='Team' className='u-margin-bottom--large'>
              <Select name='team_id' placeholder='Team' register={register} value={team_id} useDefault>
                {teams.map(team => (
                  <Select.Item key={team.id} value={team.id}>{team.name}</Select.Item>
                ))}
              </Select>
            </InputGroup>
          }

          <InputGroup title='Document'>
            <Input disabled={true} register={register} name='document_name' placeholder='-' />
          </InputGroup>
        </div>
        <hr className='divider u-margin-0' />
        <div className="u-padding">
          <CustomFields fields={customFields} watch={watch} register={register} control={control}/>
        </div>
        <div className="u-padding">
          <Button submit className='u-width-100'>Update candidate</Button>
        </div>
      </form>
    </div>
  )

}